import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from '@core/services/auth.service';
import {firstValueFrom} from 'rxjs';

export const authGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  try {
    const canActivate = await firstValueFrom(authService.checkLoginStatus());
    if(!canActivate) {
      console.warn('User is not logged in, redirecting to login');
      router.navigate(['/login']);
    }
    return canActivate;
  } catch {
    return false;
  }
};
